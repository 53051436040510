<template>
  <div class="logo-container">
    <div class="logo-container-inner flex_col-ac-jc">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="127.000000pt"
        height="122.000000pt"
        viewBox="0 0 127.000000 122.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,122.000000) scale(0.100000,-0.100000)"
          fill="none"
          stroke="black"
          stroke-width="10"
        >
          <path
            d="M1086 1185 c-59 -21 -88 -46 -115 -98 -20 -41 -24 -66 -28 -192 -6
-135 -7 -146 -30 -166 -17 -17 -39 -24 -83 -27 l-60 -4 0 -49 0 -49 40 0 c50
0 105 -22 118 -47 5 -10 12 -94 14 -188 6 -184 15 -225 66 -278 32 -33 106
-57 179 -57 l53 0 0 50 0 50 -44 0 c-62 0 -93 14 -116 52 -17 29 -20 51 -20
178 0 80 -5 163 -11 185 -11 42 -54 95 -76 95 -22 0 -15 14 16 32 52 29 64 68
70 224 4 101 10 146 20 158 24 29 67 46 116 46 l45 0 0 50 0 50 -57 0 c-32 -1
-76 -7 -97 -15z" class="curly"
          />
          <path
            d="M205 915 c-121 -47 -176 -190 -116 -305 18 -34 18 -34 -6 -72 -33
-49 -41 -114 -19 -157 10 -18 24 -35 32 -38 12 -4 11 -8 -5 -19 -59 -42 -81
-142 -45 -205 41 -72 189 -111 336 -91 151 22 228 85 236 192 6 76 -19 119
-85 151 -38 18 -72 24 -185 30 -178 9 -208 25 -176 94 8 18 16 24 27 19 117
-49 260 -14 326 79 26 37 30 50 30 112 0 39 -4 80 -8 92 -7 20 -4 22 35 25
l43 3 3 48 3 47 -89 0 c-49 0 -136 2 -193 5 -79 4 -114 1 -144 -10z m175 -90
c63 -33 81 -138 34 -194 -70 -84 -211 -48 -229 58 -18 107 96 187 195 136z
m85 -549 c33 -22 42 -59 21 -91 -28 -42 -61 -57 -144 -62 -116 -8 -192 21
-192 74 0 30 25 79 47 93 28 17 236 7 268 -14z"
            class="g"
            id="g"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LargeLogo'
}
</script>

<style scoped>
.curly {
  stroke-dasharray: 900;
  stroke-dashoffset: 666;
  animation: draw-curly 4500ms linear infinite;
}

.g {
  stroke-dasharray: 900;
  stroke-dashoffset: 1400;
  animation: draw-g 4500ms linear infinite;
}

@keyframes draw-curly {
  0% {
    stroke-dashoffset: 666;
    stroke: rgb(255, 194, 181);
  }
  25% {
    stroke-dashoffset: 1480;
    stroke: rgb(255, 138, 113);
  }
  50% {
    stroke-dashoffset: 1750;
    stroke: rgb(255, 124, 96);
  }
  75% {
    stroke-dashoffset: 2200;
    stroke: rgb(255, 129, 102);
  }
  100% {
    stroke-dashoffset: 2500;
    stroke: rgb(255, 193, 179);
  }
}

@keyframes draw-g {
  0% {
    stroke-dashoffset: 1400;
    stroke: rgb(255, 194, 181);
  }
  25% {
    stroke-dashoffset: 2200;
    stroke: rgb(255, 138, 113);
  }
  50% {
    stroke-dashoffset: 3075;
    stroke: rgb(255, 124, 96);
  }
  75% {
    stroke-dashoffset: 4000;
    stroke: rgb(255, 129, 102);
  }
  100% {
    stroke-dashoffset: 5000;
    stroke: rgb(255, 193, 179);
  }
}
</style>

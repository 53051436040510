<template>
  <div
    class="tech-container w-full max-w-830px
    flex flex-col flex-nowrap items-center justify-center">
    <ul
      class="tech-list w-full text-white
      flex flex-row flex-wrap items-start justify-center"
    >
      <tech-item
        v-for="(item, index) in techItems"
        :key="index"
        :item-data="item"
      />
    </ul>
  </div>
</template>

<script>
import TechItem from './TechItem'

export default {
  name: 'TechList',
  components: {
    TechItem
  },
  data () {
    return {
      techItems: [
        {
          id: 100,
          title: 'NodeJS',
          src: '/tech/NodeJS.svg'
        },
        {
          id: 200,
          title: 'Vue.js',
          src: '/tech/Vue.svg'
        },
        {
          id: 300,
          title: 'Nuxt.js',
          src: '/tech/Nuxt.svg'
        },
        {
          id: 400,
          title: 'Modern CSS',
          src: '/tech/CSS3.svg'
        },
        {
          id: 500,
          title: 'Tailwind CSS',
          src: '/tech/Tailwind.svg'
        },
        {
          id: 501,
          title: 'Bootstrap',
          src: '/tech/Bootstrap.svg'
        },
        {
          id: 600,
          title: 'Sass/SCSS',
          src: '/tech/Sass.svg'
        },
        {
          id: 700,
          title: 'Directus',
          src: '/tech/Directus.svg'
        },
        {
          id: 800,
          title: 'Strapi',
          src: '/tech/Strapi.svg'
        },
        {
          id: 900,
          title: 'GetCandy',
          src: '/tech/GetCandy.svg'
        },
        {
          id: 1000,
          title: 'Vue Storefront',
          src: '/tech/VueStorefront.svg'
        },
        {
          id: 1100,
          title: 'Shopware',
          src: '/tech/Shopware.svg'
        },
        {
          id: 1100,
          title: 'Magento',
          src: '/tech/Magento.svg'
        },
        {
          id: 1200,
          title: 'Docker',
          src: '/tech/Docker.svg'
        },
        {
          id: 1300,
          title: 'Git',
          src: '/tech/Git.svg'
        },
        {
          id: 1400,
          title: 'GitHub',
          src: '/tech/GitHub.svg'
        },
        {
          id: 1500,
          title: 'ESLint',
          src: '/tech/ESLint.svg'
        },
        {
          id: 1600,
          title: 'Webpack',
          src: '/tech/Webpack.svg'
        },
        {
          id: 1700,
          title: 'Yarn',
          src: '/tech/Yarn.svg'
        },
        {
          id: 1701,
          title: 'NPM',
          src: '/tech/Npm.svg'
        },
        {
          id: 1800,
          title: 'DigitalOcean',
          src: '/tech/DigitalOcean.svg'
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div id="app">
    <main
      class="index-container w-full min-h-screen p-12 bg-themeMain-reg
      flex flex-col flex-nowrap items-center justify-center"
    >
      <large-logo
        :logo-url="'/g.svg'"
      />
      <tech-list />
      <div
        v-show="isAllowed"
        class="hello-container my-4 mx-auto text-white text-sm tracking-wide
        flex flex-row flex-nowrap items-center justify-center
        lg:text-xs"
      >
      <span>
        {{ 'gintas' }}
      </span>
        <span>
        {{ '@' }}
      </span>
        <span>
        {{ 'gintas' }}
      </span>
        <span>
        {{ '.' }}
      </span>
        <span>
        {{ 'dk' }}
      </span>
      </div>
      <div
        class="hello-container my-4 mx-auto text-white text-sm tracking-wide
        flex flex-row flex-nowrap items-center justify-center
        lg:text-xs"
      >
        <a
          href="https://github.com/geantas"
          class="no-underline text-themeMain-sec
          focus:outline-none"
          rel="noreferrer"
          target="_blank"
        >
          {{ 'GitHub' }}
        </a>
      </div>
    </main>
  </div>
</template>

<script>
import LargeLogo from './components/LargeLogo'
import TechList from './components/TechList'

export default {
  name: 'App',
  components: {
    LargeLogo,
    TechList
  },
  data () {
    return {
      isAllowed: false
    }
  }
}
</script>

<style>
</style>

import Vue from 'vue'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'
import './assets/reset.css'
import './assets/tailwind.css'
import { register } from './assets/register'

Vue.config.productionTip = false

Vue.use(VueLazyload)

/* eslint-disable no-console */
if (process.env.NODE_ENV === 'production') {
  register('/service-worker.js', {
    registrationOptions: { scope: './' },
    ready () {
      // console.log('App is being served from cache by a service worker.')
    },
    registered () {
      // console.log('Service worker has been registered.')
    },
    cached () {
      // console.log('Content has been cached for offline use.')
    },
    updatefound () {
      // console.log('New content is downloading.')
    },
    updated () {
      // console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

new Vue({
  render: h => h(App)
}).$mount('#app')

<template>
  <li
    class="tech-item relative w-100px h-100px m-2 py-4 px-5 cursor-pointer
    no-underline select-disabled group
    flex flex-col flex-nowrap items-center justify-center
    hover:select-none"
    @mouseenter="showToolTip = true"
    @mouseleave="showToolTip = false"
  >
    <transition name="fade">
      <div
        v-if="showToolTip"
        class="item-tooltip"
      >
        {{ itemData.title }}
      </div>
    </transition>
    <div
      class="item-image select-disabled group
      flex flex-col flex-nowrap items-center justify-center"
    >
      <a
        v-if="itemData.title === 'GitHub'"
        href="https://github.com/geantas"
        rel="noreferrer"
        target="_blank"
        class="w-full h-full select-disabled group
        flex flex-col flex-nowrap items-center justify-center"
      >
        <img
          :src="itemData.src"
          :alt="itemData.title"
          class="w-50px object-cover grayscale transition-all select-disabled test
          group-hover:grayscale-0"
          width="50px"
          height="50px"
        >
      </a>
      <img
        v-else
        :src="itemData.src"
        :alt="itemData.title"
        class="w-50px object-cover grayscale transition-all select-disabled test2
        group-hover:grayscale-0"
        width="50px"
        height="50px"
      >
    </div>
  </li>
</template>

<script>
export default {
  name: 'TechItem',
  props: {
    itemData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      showToolTip: false
    }
  }
}
</script>

<style scoped>
li.tech-item .item-tooltip {
  @apply absolute border border-gray-100 rounded-md bg-white text-gray-600 text-center text-sm lg:text-xs;
  z-index: 2;
  top: -10px;
  padding: 8px 18px;
}

li.tech-item .item-tooltip:before {
  content: '';
  position: absolute;
  bottom: -15px;
  left: calc(50% - 8px);
  width: 0;
  height: 0;
  z-index: 3;
  border-top: 8px solid white;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

li.tech-item .item-tooltip:after {
  content: '';
  position: absolute;
  left: calc(50% - 10px);
  bottom: -16px;
  width: 0;
  height: 0;
  z-index: 2;
  border-top: 8px solid #ececec;
  border-right: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid transparent;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-all duration-200
}

.fade-enter,
.fade-leave-to {
  @apply transform translate-y-full opacity-0;
}

</style>
